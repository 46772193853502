import { render, staticRenderFns } from "./Page5-Title.vue?vue&type=template&id=408e6372&scoped=true&"
import script from "./Page5-Title.vue?vue&type=script&lang=js&"
export * from "./Page5-Title.vue?vue&type=script&lang=js&"
import style0 from "./Page5-Title.vue?vue&type=style&index=0&id=408e6372&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408e6372",
  null
  
)

export default component.exports